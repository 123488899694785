<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Import Logs</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <button type="button" @click="importLogFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="importLogsList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
            :rows="30" :lazy="true" dataKey="la1" :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="datatime" header="Import Date Time" headerStyle="width: 20%" bodyStyle="width: 20%">
                <template #body="{ data }">
                    <div>
                        {{ format_timestamp(data.da7) }}
                    </div>
                </template>
            </Column>
            <Column field="name" header="Execute Date Time" headerStyle="width: 20%" bodyStyle="width: 20%">
                <template #body="{ data }">
                    <div>
                        {{ format_timestamp(data.da8) }}
                    </div>
                </template>
            </Column>
            <Column field="passport" header="Total Records" headerStyle="width: 10%" bodyStyle="width: 10%">
                <template #body="{ data }">
                    <div class="text-uppercase">
                        {{ data.da4 }}
                    </div>
                </template>
            </Column>
            <Column field="passport" header="Successful Records" headerStyle="width: 10%" bodyStyle="width: 10%">
                <template #body="{ data }">
                    <div class="text-uppercase text-primary text-center">
                        {{ data.da5 }}
                    </div>
                </template>
            </Column>
            <Column field="error" header="Failed Records" headerStyle="width: 8%" bodyStyle="width: 8%">
                <template #body="{ data }">
                    <div class="text-uppercase text-danger">
                        {{  data.da11 ? data.da11 : '-' }}
                    </div>
                </template>
            </Column>
            <Column field="passport" header="Status" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                    <div>
                        <div class="status-import-started" v-if="data.da6 == 1">Started </div>
                        <div class="status-import-completed" v-if="data.da6 == 2">Completed</div>
                        <div v-if="data.da6 == '' || data.da6 == null">-</div>
                    </div>
                </template>
            </Column>
            <Column field="download" header="Submitted File" headerStyle="width: 10%" bodyStyle="width: 10%"
                class="justify-content-center">
                <template #body="{ data }">
                    <a v-if="data.da2 != '' && data.da2 != null" class="btn custom-outline-download-btn" target="_blank"
                        :href="'https://storage.googleapis.com/' + this.importcsvstoragefilepath + '/importVoterCsv/' + data.da2"
                        download="Download Receipt"><i class="pi pi-download download-btn-color"></i></a>
                    <span v-if="data.da2 == '' || data.da2 == null">-</span>
                </template>
            </Column>
            <Column field="download" header="Error Sheet" headerStyle="width: 10%" bodyStyle="width: 10%"
                class="justify-content-center">
                <template #body="{ data }">
                    <a v-if="data.da10 != '' && data.da10 != null" class="btn custom-outline-download-err-btn" target="_blank"
                        :href="'https://storage.googleapis.com/' + this.importcsvstoragefilepath + '/importVoterCsv/' + data.da10"
                        download="Download Receipt"><i class="pi pi-download download-btn-err-color"></i></a>
                    <span v-if="data.da10 == '' || data.da10 == null">-</span>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- filter start here -->
    <div class="modal-mask" v-if="importlogfiltermodalsflag">
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="passportlogFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">From Date</label>
                                <Calendar v-model="fromdate" :showIcon="true" class="custom-ultima-calendar"
                                    placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate"
                                    :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">To Date</label>
                                <Calendar v-model="todate" :disabled="fromdate == null" :showIcon="true"
                                    class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    :minDate="fromdate" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn"
                                :disabled="fromdate == null && todate == null" @click="
                                    btnFilterSearch()
                                    ">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" @click="resetPanFilter">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
    data() {
        return {
            importLogsList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            importlogfiltermodalsflag: false,
            todaysDate: new Date(),
            fromdate: null,
            todate: null,
            totalCharges: 0,
            client_info: '',
      importcsvstoragefilepath: '',
        };
    },
    mounted() {
    this.client_info = JSON.parse(localStorage.client_info);
    this.importcsvstoragefilepath = this.client_info.maa24;
        this.importDataList({
            page_no: this.page_no,
        });
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        importDataList(e) {
            this.loading = true;
            this.ApiService.importDataList(e).then((items) => {
                if (items.status == 200) {
                    this.importLogsList = items.data;
                    this.totalRecords = items.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.importLogsList = null;
                    this.totalCharges = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.importDataList({
                page_no: this.page_no, fromdate: this.fromdate,
                todate: this.todate, dh4: this.dh4,
                dh9: this.dh9
            });
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        importLogFilterModalOpen() {
            this.importlogfiltermodalsflag = true;
            if (this.fromdate != null) {
                this.fromdate = new Date(this.fromdate);
            }
            if (this.todate != null) {
                this.todate = new Date(this.todate);
            }
        },
        passportlogFilterModalClose() {
            this.importlogfiltermodalsflag = false;
        },
        btnFilterSearch() {
            if (this.fromdate != null && this.todate != null) {
                this.fromdate = moment(this.fromdate).format('YYYY-MM-DD');
                this.todate = moment(this.todate).format('YYYY-MM-DD');
            }
            this.importlogfiltermodalsflag = false;
            this.importDataList({
                page_no: this.page_no,
                fromdate: this.fromdate,
                todate: this.todate,
            });
        },
        resetPanFilter() {
            this.fromdate = null;
            this.todate = null;
            this.importDataList({
                page_no: this.page_no,
            });
            this.importlogfiltermodalsflag = false;
        },
        // maskedNumber(getvalue) {
        //     const maskedDigits = 'X'.repeat(getvalue.length - 4);
        //     const FirstThreeDigits = getvalue.slice(0, 2);
        //     const lastThreeDigits = getvalue.slice(-2);
        //     return FirstThreeDigits + maskedDigits + lastThreeDigits;
        // },
    },
};
</script>
<style scoped>
.status-active {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 12px;
}

.status-inactive {
    background: #ffcdd2;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #c63737;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #d7a5a5;
    line-height: 12px;
}

.profile-header-avatar {
    background: #b3bae1 0% 0% no-repeat padding-box;
    padding: 2px;
}

.service-plan-box-outer {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    padding: 6px 10px;
}

.service-plan-box-outer .call-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    user-select: none;
}

.service-plan-box-outer .call-count {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.custom-outline-download-btn {
  background: #e7f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-download-btn .download-btn-color {
  color: #5266e5;
  font-size: 13px;
}

.custom-outline-download-err-btn {
  background: #f5d8d8 0% 0% no-repeat padding-box;
  border: 1px solid #de2525;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-download-err-btn .download-btn-err-color {
  color: #de2525;
  font-size: 13px;
}
.status-import-completed {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-import-started {
  background: #fde3c8;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #bba691;
  line-height: 12px;
}
</style>